import { LoadingButton } from "@mui/lab";
import { Box, Button, styled, Typography } from "@mui/material";
import { themes } from "theme";

export const PageContainer = styled(Box)`
  padding: 58px 56px;
  background: #fbfcfe;

  & > .MuiBox-root:first-of-type {
    margin-top: 56px;
  }

  &
    > .MuiBox-root:first-of-type
    > .MuiBox-root
    > .MuiBox-root:nth-of-type(2)
    > button {
    min-width: 199px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 30px;
  }
`;

export const StyledBackground = styled(Box)({
  // width: '100vw',
  backgroundColor: themes.light.backgroundColorAlabaster,
});
export const RoundedButton = styled(Button)({
  textTransform: "unset",
  minWidth: "110px",
  fontWeight: 600,
});

export const CircleButton = styled(RoundedButton)({
  borderRadius: "24px",
});

export const StyledLoadingButton = styled(LoadingButton)({
  height: "52px",
  textTransform: "unset",
  backgroundColor: themes.light.backgroundColorBlack,
  "&:hover": {
    backgroundColor: themes.light.backgroundColorBlack,
  },
  "&:disabled": {
    backgroundColor: themes.light.backgroundColorDisabled,
    color: themes.light.colorWhite,
  },
});

export const StyledOutlinedLoadingButton = styled(StyledLoadingButton)({
  color: themes.light.colorBlack,
  backgroundColor: themes.light.backgroundColorWhite,
  border: `1px solid ${themes.light.colorBlack}`,
  "&:hover": {
    backgroundColor: themes.light.backgroundColorWhite,
  },
});

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: themes.light.backgroundColorWhite,
  padding: "40px",
  minHeight: "600px",
  width: "600px",
  [theme.breakpoints.down("md")]: {
    minWidth: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
    maxWidth: "90vw",
  },
  [theme.breakpoints.down(500)]: {
    minWidth: "400px",
    maxWidth: "420px",
    minHeight: "700px",
  },
  [theme.breakpoints.down(440)]: {
    minWidth: "300px",
    maxWidth: "320px",
  },
}));

export const CenterBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderText = styled(Typography)`
  font-family: "Lexend Tera";
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #000000;
  display: flex;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 30px;
  }
  ${(props) => props.theme.breakpoints.down(450)} {
    font-size: 26px;
  }
`;

export const ContainerExcludeTopNav = styled(Box)`
  padding: 56px;

  min-height: calc(100vh - 80px);
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 30px;
  }
`;
