import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import VerifyEmail from "./pages/Auth/VerifyEmail/VerifyEmailPage";
import { ThemeProvider } from "@mui/material";
import { themeMaterial } from "./theme";
import "./App.css";
import Amplify from "aws-amplify";
import amplifyConfig from "./aws-exports";
import PageNotFound from "pages/PageNotFound/PageNotFound";

Amplify.configure(amplifyConfig);

const App = () => { 
  return (
    <Router>
      <ThemeProvider theme={themeMaterial}>
        <Routes>
          <Route
            path="/auth/verify-email/:code/:email"
            element={<VerifyEmail />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
};

export default App;
