import { Stack, styled, Typography, Grid, Box } from "@mui/material";
import { themes } from "theme";

export const CustomBox = styled(Stack)({
  // marginTop: '2.8rem',
  // minWidth: '660px',
  padding: "1.7rem",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
});

export const CodeText = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: 535,
  textAlign: "center",
  "&:nth-of-type(2)": {
    fontSize: "1.0rem",
    fontWeight: 380,
  },
});

export const Wrapper = styled(Grid)({
  height: "100vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
});

export const GridContainer = styled(Grid)({
  justifyContent: "center",
});

export const CenterBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
