import {
  CustomBox,
  CodeText,
  Wrapper,
  GridContainer,
} from "./PageNotFoundStyled";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Wrapper container>
      <GridContainer container>
        <CustomBox>
          <img
            src="https://assets.i-refer.app/logos/ireferlogo.png" // Update the path as needed
            alt="irefer logo"
            style={{ width: 200, height: 200 }}
            onClick={() => navigate("/")}
          />
          <CodeText>
            The link you followed may be broken, or the page may have been
            removed.
          </CodeText>
        </CustomBox>
      </GridContainer>
    </Wrapper>
  );
};

export default PageNotFound;
