/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsExports = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_identity_pool_id:
    'ap-southeast-2:9b903e6f-40fd-4486-b763-d3b19436d1c8',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_XCriAzAah',
  aws_user_pools_web_client_id: '1hpohguqe5r8fett820jna97r6',
  oauth: {
    domain: 'i-refer.auth.ap-southeast-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: process.env.REACT_APP_WEBSITE_URL,
    redirectSignOut: process.env.REACT_APP_WEBSITE_URL,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE', 'APPLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_cloud_logic_custom: [
    {
      name: 'users',
      endpoint: 'https://api.i-refer.app/v1/user',
      region: 'ap-southeast-2',
    },
    {
      name: 'vendor',
      endpoint: 'https://api.i-refer.app/v1/vendor',
      region: 'ap-southeast-2',
    },
    {
      name: 'payment',
      endpoint: 'https://api.i-refer.app/v1/payment',
      region: 'ap-southeast-2',
    },
    {
      name: 'notification',
      endpoint: 'https://api.i-refer.app/v1/notification',
      region: 'ap-southeast-2',
    },
  ],
  aws_user_files_s3_bucket:
    'ireferbackend6216a0000e5d41f89c9819684353120b134832-prod',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
};

export default awsExports;
