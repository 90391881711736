import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useParams, useNavigate } from "react-router-dom";
import VerifyEmail from "../../../components/Auth/VerifyEmail/VerifyEmail";
import { CircularProgress } from "@mui/material";
import { CenterBox } from "../../../components/Common/Styled/CommonStyled";

const VerifiedEmail = () => {
  const navigate = useNavigate();
  const { code, email } = useParams();
  const [isValidLink, setIsValidLink] = useState(false);

  useEffect(() => {
    const confirmSignUp = async () => {
      if (!code || !email) {
        setIsValidLink(false);
        navigate("/invalid-link");
        return;
      }
      try {
        await Auth.confirmSignUp(email, code);
        setIsValidLink(true);
      } catch (error) {
        setIsValidLink(false);
        navigate("/invalid-link");
      }
    };

    confirmSignUp();
  }, [navigate, code, email]);

  if (isValidLink) {
    return <VerifyEmail />;
  }

  return (
    <CenterBox>
      <CircularProgress />
    </CenterBox>
  );

  // Handle other cases, like rendering an error message
};

export default VerifiedEmail;
